.inputDataFieldsSingle{
    display: flex;
    align-items: flex-start;
    justify-content: left;
    padding: 5px;
}

.regNameInput{
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: 0.5px solid grey;   
}

select{
    padding: 5px;
    font-size: 15px;
    border: 0.5px soild grey;
    border-radius: 5px;
}

.inputDataFieldsMultiple{
    display: flex;
    text-align: left;
    margin: 20px;
}


.below{
    display: flex;
    flex-direction: column;
}

.registerForm{
    width: auto;
}

@media screen and (max-width : 720px) {
    .form{
        width: 90%;
    }

    .inputDataFieldsSingle {
        flex-direction: column;
    }

    .inputDataFieldsMultiple{
        flex-direction: column;
    }

    #regSubmit{
        width: 40%;
    }

    .container{
        font-size: 10px;
    }

    h3{
        font-size: 15px;
    }
}