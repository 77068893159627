.footer{
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.footerRow{
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.footerBlocks{
    padding: 20px;
}

.footerBlockHeader{
    font-size: 20px;
    margin-bottom: 10px;
}

.footerPara{
    font-size: 15px;
    opacity: .8;
}

@media screen and (max-width : 1080px) {
    .footerRow{
        flex-direction: column;
    }
}