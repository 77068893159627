.Busroutes{
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    width: 60%;
    min-width: fit-content;
    background-color: #F6821F;
}

.row{
    display: flex;
    align-items: center;
    justify-content: center;
    border-collapse: collapse;
    border: 1px solid black;
}

.clusterRow{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #458FFF;
    font-size: larger;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
}

.clusterWrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 50px;
}

td, th{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 20px;
}

th{
    font-size: 30px;
}

.clusterRow:hover{
    cursor: pointer;
}

.hoverable:hover{
    cursor: pointer;
    color: white;
}

.main{
    display: flex;
    width: 80%;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.Left{
    font-size: larger;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Left > h3{
    padding: 10px;
}


@media screen and (max-width : 720px) {
    .table{
        width: 90%;
    }

    .main{
        width: 95%;
        text-align: center;
        flex-direction: column-reverse;
    }

    .left{
        width: 100%;
    }
    .right{
        width: 100%;
    }
}