*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.navComponent{
    cursor: pointer;
}