.Banner{
    height: auto;
    display: flex;
    padding: 20px;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
}

.NavBar{
    background-color: #F6821F;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ActualNavBar{
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 25px;
    font-weight: 500;
    width: 50%;
}

.navComponent:hover{
    background-color: transparent;
}

.Content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu{
    padding: 10px;
    color: white;
}

.mobileNavBar{
    display: none;
}

.mobileActualNavBar{
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 25px;
    font-weight: 500;
    flex-direction: column;
}

.grid{
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 60%;
    position: absolute;
    top: -60px;
}

.grid-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.collegeGridContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}

.collegeGrid{
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin: 100px 20px;
}

.collegetile{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-decoration: none;
    color: black;
    transition: transform 0.3s ease-in-out;
}

.collegetileleft{
    aspect-ratio: 16/9;
    width: auto;
    max-height: 160px;
    padding: 0px 10px;
}

.collegetileright{
    padding: 10px;
    text-align: center;
    background-color: white;
    font-family: 'Poppins', sans-serif;
}

.collegeul{
    list-style: none;
}

.collegeh3, .collegep{
    padding: 3px;
}

.collegetile:hover{
    transform: translateY(-15px);
    background-color: rgba(128, 128, 128, 0.1);
}

@media screen and (max-width : 720px) {
    .Content{
        width: 100%;
    }
}

@media screen and (max-width : 840px){
    .ActualNavBar{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .grid{
        display: grid;
        grid-template-columns: repeat(2, auto);
        position: relative;
        top: 0px;
        width: 100%;
    }
    .collegeGrid{
        grid-template-columns: repeat(1, auto);
        margin: 50px 20px;
    }
}

@media screen and (max-width : 1080px){
    .Banner{
        flex-direction: column;
    }
}


@media screen and (max-width : 420px){
    .NavBar{
        height: max-content;
    }
    .ActualNavBar{
        display: none;
    }
    .mobileNavBar{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .navComponent{
        padding: 10px;
    }
}