.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(0, 0, 0, 0.719);
  border-radius: 50%;

  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}
