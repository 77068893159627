.superAdminContainer{
    height: 100vh;
}

.superAdminScreen{
    min-height: 90%;
    max-height: auto;
    display: flex;
}

.left{
    width: fit-content;
    z-index: 1000;
}

.right{
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 20px;
}

.right::-webkit-scrollbar {
    display: none;
}

.navbar {
    height: 100%;
    width: fit-content;
    transition: width 0.3s;
    overflow: hidden;
    white-space: nowrap;
    background-color: #F6821F;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
.navbar.collapsed {
    width: 50px;
}
  
.menu div {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
}
  
.menu div:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}
  