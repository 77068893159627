.MODAL_STYLES{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    z-index: 1000;
    width: 60%;
    max-height: 80%;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 10px;
}

.MODAL_STYLES::-webkit-scrollbar{
    display: none;
}


.OVERLAY_STYLES {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}


.CROSS_STYLES {
    width: 100%;
    text-align: right;
    z-index: 1000;
    position: fixed;
    padding: 30px;
}

.children{
    margin-top: 30px;
    padding: 30px;
}

.closeIcon{
    font-size: 30px;
}

.closeIcon:hover{
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    cursor: pointer;
    border-radius: 10px;
}

@media screen and (max-width : 720px) {
    .MODAL_STYLES{
        width: 80%;
    }
}