.busOperations{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.buttons{
    display: flex;
    width: max-content;
    padding: 5px;
    margin: 5px;
    color: white;
    border: 1px solid black;
    border-radius: 50%;
    flex-wrap: nowrap;
    font-size: 20px;
    font-weight: 600;
}

.buttons:hover{
    background-color: rgba(0, 0, 0, 0.2);
}

.buttons:hover{
    cursor: pointer;
}

.busRegForm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.busForm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}

.stopsCheckBox{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 3px;
}


.stopsTable{
    height: 170px;
    text-align: left;
    overflow-y: scroll;
    box-sizing: border-box;
    border: 1px solid grey;
}


.stopsTable{
    margin: 10px;
    padding: 5px;
}

.CheckBoxListView{
    transform: scale(1.5);
    width: fit-content;
}

.borderForRow{
    border: 1px solid black;
}