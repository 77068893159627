.Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; 
}

.form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid grey;
    padding: 10px;
    border-radius: 10px;
    width: 40%;
}


.label{
    padding: 5px;
}

.innerWrapper{
    width: 80%;

}

.container > h1{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    margin: 20px 0px;
}

.inputBox{
    padding: 10px;
}

.loginInput{
    padding: 10px;
    border-radius: 10px;
    border: 0.3px solid grey;
    font-size: 15px;
}

button{
    padding: 8px;
    border-radius: 10px;
    border: 0.3px solid grey;
    cursor: pointer;
    background-color: #F6821F;
    color: black;
    font-size: 20px;
    font-weight: bold;
}

.submit{
    padding: 20px;
}

@media screen and (max-width : 720px) {
    .form{
        width: 90%;
    }
}