.wrapper{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.box{
    border: 1px solid grey;
    width: 80%;
    padding: 10px;
    border-radius: 5px;
}

.aboutHeading{
    padding: 10px;
}

.aboutPara{
    width: 80%;
    padding: 10px;
}

.paraInner{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.missionPara{
    flex-direction: column;
}

@media screen and (max-width : 840px){
    .box{
        width: 100%;
    }
    .aboutPara{
        width: 95%;
        padding: 7px;
    }
}